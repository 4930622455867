const configuration = {
  //baseUrl: "http://localhost:8000/",
  baseUrl: "https://dev.vaisnavamarriage.com/",
  // baseUrl: "https://vaisnavamarriage.com/",

  apis: {
    login: "api/login",
    register: "api/register",
    forgetPassword: "api/password-reset",
    resetPassword: "api/password-reset",
    adminLogin: "api/adminLogin",
    filter: "api/filter",
    saveProfile: "api/saveProfileSection",
    savePhoto: "api/savePhoto",
    singleUser: "api/getUserDetails",
    matches: "api/matches",
    getMatches: "api/getMatches",
    changeStatus: "/api/changeStatus", //accept/reject matches
    sendMatches: "api/sendMatches",
    addNotes: "api/addNotes", // SAVE ADMIN NOTES API
    getNotes: "api/getNotes", //GET ADMIN NOTES API
    changePassword: "api/changePassword",
    getAllAdmins: "api/getAllAdmins",
    sendRequest: "api/sendRequest",
    adminApprovalPending: "api/adminApprovalPending",
    adminApproval: "api/adminApproval",
    updateNote: "/api/updateNote",
    deleteNote: "/api/deleteNote",
    searchAndSent: "api/searchAndSendMatches",
    userFilter: "api/userfilter",
    adminApprove: "api/approveRequests",
    userGetRequest: "api/userApprovalPending",
    userApproval: "api/userApproval",
    getNotification: "api/getNotification",
    approveRequest: "/api/getApprovedRequests",
    readNotification: "/api/readNotification",
    sendApproval: "api/sendApproval",
    getAllQuestionnaireRequests: "api/getAllQuestionnaireRequests",
    questionnaireApproval: "api/questionnaireApproval",
    getQuestions: "api/getQuestions",
    overAllApproval: "api/overAllApproval",
    getAcceptedUsers: "api/getAcceptedUsers",
    advanceSearch: "api/search",
    deleteUser: "api/deleteUser",
    updateMarital: "api/updateMarital",
    getAllMarried: "api/married",
    changePassword: "api/changePassword",
    uploadFiles: "api/uploadFile",
    addEmail: "api/addEmail",
    verifyEmail: "api/verifyAndSave",
    changePrimaryEmail: "api/changePrimaryEmail",
    deleteEmail: "api/deleteEmail",
    blockUser: "api/blockUser",
    getBlockedUsers: "api/getBlockedUsers",
    requestDetail: "api/getRequestByUserId",
    getSuggestionHistory: "api/suggestionHistory",
    getAllUserVerifyRequests: "api/getAllUserVerifyRequests",
    userVerifyApproval: "api/userVerifyApproval",
    userVerify:"api/userVerification",
  },
};

export default configuration;
